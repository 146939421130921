import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Deleger oppgaver" />
    <div className="longtext">
      <h2>Deleger oppgaver</h2>

      <p>
        Når en liste er delt kan du sette hvem som har ansvar for ulike
        oppgaver. Dette kan du gjøre på en av disse måtene:
      </p>
      <ul>
        <li>Åpne oppgaven og velg person</li>
        <li>Kun iOS: marker flere oppgaver og trykk på personikonet</li>
        <li>Kun macOS: marker flere oppgaver og trykk Command-Shift-D</li>
      </ul>

      <p>
        Fordelene med å delegere en oppgave er at det blir tydelig hvem som har
        ansvaret for den. I tillegg vil:
      </p>
      <ul>
        <li>
          Oppgaven bare være synlig i "I dag", "Fremtidig" eller "Neste" hos den
          som er ansvarlig (men fortsatt være synlig for alle i listen den hører
          hjemme)
        </li>
        <li>Påminnelser bare vises hos den som er ansvarlig for oppgaven</li>
        <li>
          Oppgaven vil være synlig for andre medlemmer under "Venter"-listen. På
          den måten kan man lett se hvilke oppgaver du venter på at andre skal
          gjøre noe med.
        </li>
      </ul>
    </div>
  </Layout>
)

export default Page
